@font-face {
  font-family: 'LMSans';
  src: url('/assets/font/LeroyMerlinSans-Web-Regular.ttf');
}
@font-face {
  font-family: 'LMSans';
  src: url('/assets/font/LeroyMerlinSans-Web-Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'LMSans';
  src: url('/assets/font/LeroyMerlinSans-Web-SemiBold.ttf');
  font-weight: 500;
}

body {
  font-family: 'LMSans', sans-serif !important;
}
@media screen and (orientation: landscape) {
  html {
    /* Rotate the content container */
    transform: rotate(-90deg);
    transform-origin: left top;
    /* Set content width to viewport height */
    width: 100vh !important;
    /* Set content height to viewport width */
    height: 100vw !important;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
